<template>
    <b-row>
        <b-col md="12">
            <b-card>
                <b-form @submit.stop.prevent @submit="onSubmit">
                    <b-row>
                        <b-col md="12">
                            <b-form-group
                                class="mb-0"
                                label="Pregunta">
                                <b-form-textarea
                                    v-model="question"
                                    placeholder="Ingrese la pregunta e instrucciones ..."
                                    rows="5"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <hr>
                            <b-form-group
                                class="mb-0"
                                label="Tipo de pregunta">
                                <b-form-select v-model="selected" :options="options"></b-form-select>
                            </b-form-group>
                            <hr>
                        </b-col>
                        <b-col md="12" v-if="selected">
                            <b-form-group
                                label="Alternativas:"
                                ref="alternatives"
                                class="question-options"
                                :style="{height: trHeight}">
                                <b-form-radio
                                    v-if="selected == '1'"
                                    class="b-form-radio"
                                    v-for="(alternative, index) in alternatives"
                                    :key="index"
                                    v-model="selected2"
                                    :value="alternative"
                                    ref="alt">
                                    <b-form-input v-model="alternative.text"></b-form-input>
                                    <b-button
                                        v-if="index !== 0"
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        variant="outline-danger"
                                        @click="removeAlternative(index, 'radio', alternative.id)">
                                        <feather-icon icon="Trash2Icon" />
                                    </b-button>
                                </b-form-radio>
                                <b-form-checkbox
                                    v-if="selected == '2'"
                                    class="b-form-radio"
                                    v-for="(alternative, index) in alternatives"
                                    :key="index"
                                    v-model="selected2"
                                    :value="alternative"
                                    ref="alt">
                                    <b-form-input v-model="alternative.text"></b-form-input>
                                    <b-button
                                        v-if="index !== 0"
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        variant="outline-danger"
                                        @click="removeAlternative(index, 'checkbox', alternative.id)">
                                        <feather-icon icon="Trash2Icon" />
                                    </b-button>
                                </b-form-checkbox>
                            </b-form-group>
                            <hr>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                size="sm"
                                @click="addAlternative">
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                />
                                <span>AGREGAR ALTERNATIVA</span>
                            </b-button>
                            <hr>
                        </b-col>

                        <!-- submit -->
                        <b-col>
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                class="mr-1">
                                AGREGAR
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import Toast from 'vue-toastification';
    import Vue from 'vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadio, BFormSelect, BFormTextarea, BRow } from 'bootstrap-vue';
    import {heightTransition} from '@core/mixins/ui/transition';
    import Ripple from 'vue-ripple-directive';
    import axios from 'axios';

    Vue.use(Toast);

    export default {
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BFormRadio,
            BForm,
            BButton,
            BCard,
            BFormTextarea,
            BFormSelect,
        },
        directives: {
            Ripple,
        },
        mixins: [heightTransition],
        data (){
            return {
                question: '',
                selected: null,
                options: [
                    { value: null, text: 'Seleccione un tipo de pregunta' },
                    { value: 1, text: 'Una respuesta' },
                    { value: 2, text: 'Varias respuestas' },
                ],

                selected2: {},
                nextTodoId: 2,
                alternatives: [
                    {id: 1, text: ''},
                ]
            }
        },
        watch: {
            selected(newValue){
                if (newValue){
                    this.initTrHeight();
                    if (newValue == 1){
                        this.selected2 = {};
                    } else {
                        this.selected2 = [];
                    }
                }
            }
        },
        methods: {
            onSubmit () {

                let error = 0;
                let errorE1 = 0;
                let errorG = 0;

                this.alternatives.map(item => {
                    if (item.text === ''){
                        error = 1;
                    }
                });

                if (this.question === '' || !this.selected || error){
                    errorE1 = 1;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe completar toda la información.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        }
                    });

                }

                if (!errorE1 && this.selected == 1){

                    if (!this.selected2.id){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Debe seleccionar una opción correcta.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            }
                        });
                        errorG = 1;
                    }

                }

                if (!errorE1 && this.selected == 2){
                    if (this.selected2.length < 2){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Debe seleccionar 2 opciones correctas, como mínimo.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            }
                        });
                        errorG = 1;
                    }
                }

                if (!errorG && !errorE1){

                    let alternatives = [];
                    this.alternatives.map(item => {

                        let isCorrect = 0;
                        if (this.selected == 1){
                            if (this.selected2.id == item.id){
                                isCorrect = 1;
                            }
                        } else {
                            let found = this.selected2.find(i => i.id == item.id);
                            if (found){
                                isCorrect = 1;
                            }
                        }

                        alternatives.push({
                            answer: item.text,
                            isCorrect
                        });
                    });

                    let questionData = {
                        question: this.question,
                        questionTypeId: this.selected,
                        alternatives
                    };

                    axios
                        .post(`${process.env.VUE_APP_HOST}/api/dashboard/question/create`, questionData)
                        .then(response => {

                            this.clearData();

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                }
                            });

                        })
                        .catch(err => {
                            let message = err.response.data.message ? err.response.data.message : 'Error al crear la pregunta.';
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            });
                        });
                }

            },
            addAlternative() {
                this.alternatives.push({
                    id: this.nextTodoId,
                    text: ''
                });

                this.nextTodoId += 1;

                this.$nextTick(() => {
                    this.trAddHeight(this.$refs.alt[0].$el.offsetHeight);
                });
            },
            clearData (){
                this.question = '';
                this.selected = null;
                this.selected2 = {};
                this.nextTodoId = 2;
                this.alternatives = [
                    {id: 1, text: ''}
                ];
            },
            removeAlternative(index, type, id) {

                this.alternatives.splice(index, 1);

                if (type === 'radio'){

                    if (this.selected2.id == id){
                        this.selected2 = {};
                    }

                } else {
                    this.selected2 = this.selected2.filter(u => u.id !== id);
                }

                this.trTrimHeight(this.$refs.alt[0].$el.offsetHeight);
            },
            initTrHeight() {
                if (this.selected){
                    this.trSetHeight(null);
                    this.$nextTick(() => {
                        this.trSetHeight(this.$refs.alternatives.scrollHeight);
                    });
                }
            }
        }
    }

</script>

<style lang="scss" scoped>
    .question-options {
        overflow: hidden;
        transition: .35s height;
    }
</style>

<style scoped>

    .custom-control-label .form-control {
        width: 90%;
        display: inline-block;
        margin-right: 1rem;
    }

</style>

<style>

    .question-options .custom-radio .custom-control-label,
    .question-options .custom-checkbox .custom-control-label {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .question-options .custom-radio .custom-control-label::before,
    .question-options .custom-checkbox .custom-control-label::before {
        top: 0.72rem !important;
    }

    .question-options .custom-radio .custom-control-label::after,
    .question-options .custom-checkbox .custom-control-label::after {
        top: 0.72rem !important;
    }

    .question-options .custom-control.custom-radio,
    .question-options .custom-control.custom-checkbox {
        margin-bottom: 1rem;
    }

    .question-options .custom-control.custom-radio:nth-last-child(1),
    .question-options .custom-control.custom-checkbox:nth-last-child(1) {
        margin-bottom: 0;
    }

</style>
